export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: Date;
  AWSDateTime: Date;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type AnimalInput = {
  /**   Required when origin stage has multiple outputs */
  name?: InputMaybe<Scalars['String']>;
  /**  # Specify the origin facility ID of the animals that are coming from another facility in the system. */
  originId: Scalars['ID'];
  /**  # The number of animals. */
  quantity: Scalars['Float'];
  /**  # The transport of the animal input to go to the facility. */
  transport: Array<Transport>;
};

export type AnimalProcessing = {
  /**  # A list of facilities for processing the animals. */
  facilities: Array<Facility>;
};

export type AnimalsIncoming = {
  /**  # The type of origin of the animals. */
  origin: InputAnimalOrigin;
  /**  # If the animals are coming from within the same production system, specify the name of the output. */
  originOutputName?: InputMaybe<Scalars['String']>;
  /**  # Specify an origin stage ID if the animals are coming from another stage in the system. */
  originStageId?: InputMaybe<Scalars['ID']>;
  /**  # transport of the animals to the farm */
  transport?: InputMaybe<Array<InputMaybe<Transport>>>;
  /**  # The total weight of this input of animals, that is coming into the stage. */
  weight: Scalars['Float'];
};

export type AnimalsOutgoing = {
  /**  # Indicate if an impact result is needed, even if this output is not marked as external. Default: false */
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  /**  # A unique name to differentiate between the different outputs of the stage, eg 'partial harvest 26/10/2023 at 3 months' */
  name?: InputMaybe<Scalars['String']>;
  /**  # The price per kg shrimp of this output. Only needed for stages with multiple outputs, for economic allocation. */
  price?: InputMaybe<Scalars['Float']>;
  /**
   *  # The protein content of the shrimp at this output, in weight %.
   * # Default: 17.8% for LITOPENAEUS_VANNAMEI, 18.8% for PENAEUS_MONODON
   */
  proteinContent?: InputMaybe<Scalars['Float']>;
  /**  # Indicate if this output is sold externally. If sold, an impact will be calculated. */
  soldExternally: Scalars['Boolean'];
  /**  # The total weight of this output of animals, that is leaving the stage. */
  weight: Scalars['Float'];
};

export type AuxiliaryInputs = {
  /**  # A list of chemicals that are used to clean the facility. */
  chemicals?: InputMaybe<Array<Chemical>>;
  /**  # A list of refrigerants that are used to cool the facility. */
  refrigerants?: InputMaybe<Array<Refrigerant>>;
  /**  # The amount of wastewater that will be discharged to the sewage treatment plant. In cubic meter per year. */
  wastewaterAmount: Scalars['Float'];
};

export type Chemical = {
  /**  # The used amount of the chemical. In kilogram per year. */
  amount: Scalars['Float'];
  type: ChemicalType;
};

export enum ChemicalType {
  HydrochloricAcid = 'HYDROCHLORIC_ACID',
  HydrogenPeroxide = 'HYDROGEN_PEROXIDE',
  PotassiumHydroxide = 'POTASSIUM_HYDROXIDE',
  SodiumHydroxide = 'SODIUM_HYDROXIDE',
  SodiumHypochlorite = 'SODIUM_HYPOCHLORITE'
}

export type CompoundFeed = {
  compoundFeedId: Scalars['ID'];
  energyCarriers?: InputMaybe<Array<EnergyCarrier>>;
  feedmillLocation: Scalars['String'];
  ingredientUses: Array<IngredientUse>;
  name: Scalars['String'];
  outboundTransport?: InputMaybe<Array<Transport>>;
  water?: InputMaybe<Array<Water>>;
};

export type CompoundFeedUse = {
  /**  # Weight percentage crude protein as a fraction of the feed weight. */
  crudeProteinContent: Scalars['Float'];
  /**  # ID to of the compound feed. */
  id: Scalars['ID'];
  /**  # ID of precalculated impacts to import. */
  importedImpactId?: InputMaybe<Scalars['ID']>;
  /**  # Total of the whole herd over the whole duration of the stage, in kilogram. */
  quantity: Scalars['Float'];
};

/**   APS ENUMS */
export enum DatabaseFoundation {
  Afp6 = 'AFP6',
  Gfli = 'GFLI'
}

/**   RESULT MODELS */
export type EmissionResult = {
  __typename?: 'EmissionResult';
  magnitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
};

export type EnergyCarrier = {
  /**  # The amount of the energy carrier. The unit is based on the EnergyCarrierTypeAps. */
  amount: Scalars['Float'];
  type: EnergyCarrierType;
};

export enum EnergyCarrierType {
  /**  The burning of coal, in Mega Joule. */
  Coal = 'COAL',
  /**  The burning of diesel, in Mega Joule. */
  Diesel = 'DIESEL',
  /**  The electricity use from the grid, in kWh. */
  Electricity = 'ELECTRICITY',
  /**  The electricity use from the solar panels, in kWh. */
  ElectricityPhotovoltaic = 'ELECTRICITY_PHOTOVOLTAIC',
  /**  The electricity use from the wind, in kWh. */
  ElectricityWind = 'ELECTRICITY_WIND',
  /**  The burning of gasoline, in Mega Joule. */
  Gasoline = 'GASOLINE',
  HeavyFuelOil = 'HEAVY_FUEL_OIL',
  /**  The burning of natural gas, in Mega Joule. */
  NaturalGas = 'NATURAL_GAS',
  /**  The burning of propane or Liquefied Petroleum Gas, in Mega Joule. */
  PropaneOrLpg = 'PROPANE_OR_LPG'
}

export type Facility = {
  /**  # The auxiliary inputs for the entire facility. */
  auxiliaryInputs: AuxiliaryInputs;
  /**  # The utilities used in the facility, divided in Energy Carriers and Water. Use is per year. */
  energyCarriers: Array<EnergyCarrier>;
  /**  # The location of the facility, typically the country. */
  geography: Scalars['String'];
  /**  # A UUID that should be provided for each facility, in order to link the facilities between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # A description of all the animals entering the facility. */
  inputs: FacilityInputs;
  name?: InputMaybe<Scalars['String']>;
  /**  # A description of all the animals leaving the facility. */
  outputs: FacilityOutputs;
  water: Array<Water>;
};

export type FacilityInputs = {
  /**  # A list of animal inputs to go into the facility. */
  animalInputs: Array<AnimalInput>;
  /**  # The average weight of all animals that go into the facility. In kilogram. */
  averageWeight: Scalars['Float'];
  /**  # The total live weight entering the entire facility, this can be more than the target animal input flow. In kilogram per year. */
  totalLiveWeightEntering: Scalars['Float'];
};

export type FacilityOutputs = {
  cat1And2MaterialAndWaste: OutputSpecification;
  cat3HidesAndSkins: OutputSpecification;
  cat3SlaughterByProducts: OutputSpecification;
  foodGradeBones: OutputSpecification;
  foodGradeFat: OutputSpecification;
  freshMeatAndEdibleOffal: OutputSpecification;
};

export type FeedingProgram = {
  /**  # List of used compound feeds. */
  compoundFeeds?: InputMaybe<Array<InputMaybe<CompoundFeedUse>>>;
  fertilizers?: InputMaybe<Array<InputMaybe<Fertilizer>>>;
  /**  # List of used single ingredients. */
  singleIngredientUses?: InputMaybe<Array<InputMaybe<SingleIngredientUse>>>;
};

export type Fertilizer = {
  /**  # The amount used during the stage, in kilogram. */
  quantity: Scalars['Float'];
  type: FertilizerType;
};

export enum FertilizerType {
  /**  Ammonia, as 100% NH3 (NPK 82-0-0) */
  Ammonia = 'AMMONIA',
  /**  Ammonium nitrate, as 100% (NH4)(NO3) (NPK 35-0-0) */
  AmmoniumNitrate = 'AMMONIUM_NITRATE',
  /**  Ammonium sulfate, as 100% (NH4)2SO4 (NPK 21-0-0) */
  AmmoniumSulfate = 'AMMONIUM_SULFATE',
  /**  Calcium ammonium nitrate (CAN), (NPK 26.5-0-0) */
  CalciumAmmoniumNitrate = 'CALCIUM_AMMONIUM_NITRATE',
  /**  Di ammonium phosphate, as 100% (NH3)2HPO4 (NPK 22-57-0) */
  DiAmmoniumPhosphate = 'DI_AMMONIUM_PHOSPHATE',
  /**  Liquid urea-ammonium nitrate solution (NPK 30-0-0) */
  LiquidUreaAmmoniumNitrateSolution = 'LIQUID_UREA_AMMONIUM_NITRATE_SOLUTION',
  /**  NPK compound (NPK 15-15-15) */
  NpkCompound = 'NPK_COMPOUND',
  /**  Phosphate rock (32% P2O5, 50% CaO) (NPK 0-32-0) */
  PhosphateRock = 'PHOSPHATE_ROCK',
  /**  PK compound (NPK 0-22-22) */
  PkCompound = 'PK_COMPOUND',
  /**  Potassium chloride (NPK 0-0-60) */
  PotassiumChloride = 'POTASSIUM_CHLORIDE',
  /**  Potassium sulfate (NPK 0-0-50) */
  PotassiumSulfate = 'POTASSIUM_SULFATE',
  /**  Silicon dioxide */
  SiliconDioxide = 'SILICON_DIOXIDE',
  /**  Single superphosphate, as 35% Ca(H2PO4)2 (NPK 0-21-0) */
  SingleSuperphosphate = 'SINGLE_SUPERPHOSPHATE',
  /**  Sodium nitrate */
  SodiumNitrate = 'SODIUM_NITRATE',
  /**  Triple superphosphate, as 80% Ca(H2PO4)2 (NPK 0-48-0) */
  TripleSuperphosphate = 'TRIPLE_SUPERPHOSPHATE',
  /**  Urea, as 100% CO(NH2)2 (NPK 46.6-0-0) */
  Urea = 'UREA'
}

export type GrowingFarm = {
  /**  # The age of the farm, in years. */
  age: Scalars['Float'];
  chemicals?: InputMaybe<Array<InputMaybe<ShrimpProductionChemical>>>;
  /**  # Amount is for all modelled stages in the farm in total. */
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  /**  # The location of the farm, typically the country. */
  geography: Scalars['String'];
  ice?: InputMaybe<Array<InputMaybe<Ice>>>;
  /**  # The materials used for the construction of the ponds */
  materials?: InputMaybe<Array<InputMaybe<GrowingStageMaterial>>>;
  stages?: InputMaybe<Array<InputMaybe<GrowingStage>>>;
  /**  # Use of fresh water, groundwater or tap water. In cubic meter for the total of the stages in the farm. */
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type GrowingStage = {
  chemicals?: InputMaybe<Array<InputMaybe<ShrimpProductionChemical>>>;
  /**  # Time that the pond is empty within the stage duration, in days. Default: 0 */
  emptyPeriod?: InputMaybe<Scalars['Float']>;
  /**  # Amount is over the duration of the stage. */
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedingProgram: FeedingProgram;
  ice?: InputMaybe<Array<InputMaybe<Ice>>>;
  /**  # A UUID that should be provided for each stage, in order to link the stages between which animals are exchanged. */
  id: Scalars['ID'];
  input: Array<AnimalsIncoming>;
  /**  # The materials used for the construction of the ponds */
  materials?: InputMaybe<Array<InputMaybe<GrowingStageMaterial>>>;
  /**  # A custom stage name. */
  name?: InputMaybe<Scalars['String']>;
  output: Array<AnimalsOutgoing>;
  /**  # The area of all ponds modelled in the stage, in ha. */
  pondProductionArea: Scalars['Float'];
  pondWaterProperties?: InputMaybe<PondWaterProperties>;
  /**  # The duration of the stage, in days. */
  stageDuration: Scalars['Float'];
  /**   description of the stock present at the start and/or end of the stage */
  stock?: InputMaybe<Stock>;
  /**  # Use of fresh water, groundwater or tap water. In cubic meter during the total of the stage. */
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type GrowingStageMaterial = {
  /**  # The amount used for construction of the stage pond(s). */
  amount: Scalars['Float'];
  /**  # The expected life time of the material used for the construction of the pond, in years. */
  lifeTime: Scalars['Float'];
  /**  # Transport of the construction materials to the farm. */
  transport?: InputMaybe<Array<InputMaybe<Transport>>>;
  type: GrowingStageMaterialType;
};

export enum GrowingStageMaterialType {
  CastIron = 'CAST_IRON',
  ConcreteBlock = 'CONCRETE_BLOCK',
  Gravel = 'GRAVEL',
  /**
   *  Units: kg
   * # Polyethylene
   */
  HdpeGranulates = 'HDPE_GRANULATES',
  LdpeGranulates = 'LDPE_GRANULATES',
  LowAlloyedSteel = 'LOW_ALLOYED_STEEL',
  Nylon = 'NYLON',
  /**  Polyvinylchloride */
  Pvc = 'PVC',
  Rebar = 'REBAR',
  /**  Units: m³ */
  Wood = 'WOOD'
}

export type HatcheryFarm = {
  /**  # The age of the farm, in years. */
  age: Scalars['Float'];
  chemicals?: InputMaybe<Array<InputMaybe<ShrimpProductionChemical>>>;
  /**  # Amount is for all modelled stages in the farm in total. */
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  /**  # The location of the farm, typically the country. */
  geography: Scalars['String'];
  ice?: InputMaybe<Array<InputMaybe<Ice>>>;
  /**  # The materials used for the construction of the ponds */
  materials?: InputMaybe<Array<InputMaybe<HatcheryStageMaterial>>>;
  stages?: InputMaybe<Array<InputMaybe<HatcheryStage>>>;
  /**  # Use of fresh water, groundwater or tap water. In cubic meter for the total of the stages in the farm. */
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type HatcheryFeedingProgram = {
  /**  # List of used compound feeds. */
  compoundFeeds?: InputMaybe<Array<InputMaybe<CompoundFeedUse>>>;
  fertilizers?: InputMaybe<Array<InputMaybe<Fertilizer>>>;
  naturalFeed?: InputMaybe<Array<InputMaybe<NaturalFeed>>>;
  /**  # List of used single ingredients. */
  singleIngredientUses?: InputMaybe<Array<InputMaybe<SingleIngredientUse>>>;
};

export type HatcheryStage = {
  chemicals?: InputMaybe<Array<InputMaybe<ShrimpProductionChemical>>>;
  /**  # Time that the pond is empty within the stage duration, in days. Default: 0 */
  emptyPeriod?: InputMaybe<Scalars['Float']>;
  /**  # Amount is over the duration of the stage. */
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedingProgram: HatcheryFeedingProgram;
  ice?: InputMaybe<Array<InputMaybe<Ice>>>;
  /**  # A UUID that should be provided for each stage, in order to link the stages between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # The materials used for the construction of the ponds */
  materials?: InputMaybe<Array<InputMaybe<HatcheryStageMaterial>>>;
  /**  # A custom stage name. */
  name?: InputMaybe<Scalars['String']>;
  output: Array<AnimalsOutgoing>;
  /**  # The area of all ponds modelled in the stage, in ha. */
  pondProductionArea: Scalars['Float'];
  pondWaterProperties?: InputMaybe<PondWaterProperties>;
  /**  # The duration of the stage, in days. */
  stageDuration: Scalars['Float'];
  /**   description of the stock present at the start and/or end of the stage */
  stock?: InputMaybe<Stock>;
  /**  # Use of fresh water, groundwater or tap water. In cubic meter during the total of the stage. */
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type HatcheryStageMaterial = {
  /**  # The amount used for construction of the stage pond(s). */
  amount: Scalars['Float'];
  /**  # The expected life time of the material used for the construction of the pond, in years. */
  lifeTime: Scalars['Float'];
  /**  # Transport of the construction materials to the farm. */
  transport?: InputMaybe<Array<InputMaybe<Transport>>>;
  type: HatcheryStageMaterialType;
};

export enum HatcheryStageMaterialType {
  Aluminium = 'ALUMINIUM',
  CastIron = 'CAST_IRON',
  ConcreteBlock = 'CONCRETE_BLOCK',
  Gravel = 'GRAVEL',
  /**
   *  Units: kg
   * # Polyethylene
   */
  HdpeGranulates = 'HDPE_GRANULATES',
  LdpeGranulates = 'LDPE_GRANULATES',
  LowAlloyedSteel = 'LOW_ALLOYED_STEEL',
  Nylon = 'NYLON',
  /**  Polyvinylchloride */
  Pvc = 'PVC',
  Rebar = 'REBAR',
  /**  Units: m³ */
  Wood = 'WOOD'
}

export type Ice = {
  /**  # The amount used during the stage, in kilogram. */
  amount: Scalars['Float'];
  type: IceType;
};

export enum IceType {
  Ice = 'ICE'
}

export type Impact = {
  category: Scalars['String'];
  quantity: Scalars['Float'];
  unit: Scalars['String'];
};

export type ImpactAssessmentResult = {
  __typename?: 'ImpactAssessmentResult';
  category?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unit?: Maybe<Scalars['String']>;
};

export type ImportedImpact = {
  id: Scalars['ID'];
  impacts: Array<Impact>;
  productName: Scalars['String'];
};

export type Ingredient = {
  addonType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  origin: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
};

export type IngredientUse = {
  /**  # ID of precalculated impacts to import. */
  importedImpactId?: InputMaybe<Scalars['ID']>;
  inboundTransport?: InputMaybe<Array<InputMaybe<Transport>>>;
  ingredient: Ingredient;
  /**  # Unit: kg */
  quantity: Scalars['Float'];
};

export enum InputAnimalOrigin {
  BackgroundData = 'BACKGROUND_DATA',
  ProductionSystem = 'PRODUCTION_SYSTEM'
}

export type NaturalFeed = {
  /**  # Weight percentage crude protein as a fraction of the feed weight. Default per type if available. */
  crudeProteinContent?: InputMaybe<Scalars['Float']>;
  /**  # Country or region where the feed is produced. Default: location of the farm. */
  origin?: InputMaybe<Scalars['String']>;
  /**  # The amount used during the stage, in kilogram. */
  quantity: Scalars['Float'];
  /**  # A list of transports used to move the feed to the farm. Required of origin is different from the farm geography. */
  transport?: InputMaybe<Array<InputMaybe<Transport>>>;
  type: NaturalFeedType;
};

export enum NaturalFeedType {
  /**  Artemia, default protein content: 52.2% */
  Artemia = 'ARTEMIA',
  /**  Natural feed (fish products), no default protein content available. */
  NaturalFeedFish = 'NATURAL_FEED_FISH',
  /**  Yeast, default protein content: 48.9% based on dried yeast with a DM content of 93.6% */
  Yeast = 'YEAST'
}

export type OutputSpecification = {
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  packaging?: InputMaybe<Packaging>;
  /**
   *  # The price of the specific output. Used for economic allocation. In euro.
   * # Default values are available for pigs and cattle, it is a required field for the other animal types.
   */
  price?: InputMaybe<Scalars['Float']>;
  /**  # The weight of the specific output. In kilogram. */
  weight: Scalars['Float'];
};

export type Packaging = {
  primaryPackaging?: InputMaybe<Array<PrimaryPackaging>>;
  secondaryPackaging?: InputMaybe<Array<SecondaryPackaging>>;
  tertiaryPackaging?: InputMaybe<Array<TertiaryPackaging>>;
};

export type PondWaterProperties = {
  salinity: Salinity;
  /**   Description if the water flowing into the system, including brackish and salty water. */
  waterIn?: InputMaybe<WaterProperties>;
  /**   Description if the water flowing into the pond, including brackish and salty water. */
  waterOut?: InputMaybe<WaterProperties>;
};

/**   ANIMAL PROCESSING MODELS */
export type PrimaryPackaging = {
  /**  # The amount of primary packaging material. In kilogram. */
  amount: Scalars['Float'];
  type: PrimaryPackagingType;
};

/**   ANIMAL PROCESSING ENUMS */
export enum PrimaryPackagingType {
  AluminiumFoil = 'ALUMINIUM_FOIL',
  HdpeGranulates = 'HDPE_GRANULATES',
  LdpeGranulates = 'LDPE_GRANULATES',
  Paper = 'PAPER',
  Polyester = 'POLYESTER',
  Polypropylene = 'POLYPROPYLENE',
  Polystyrene = 'POLYSTYRENE'
}

/**  # The impacts and emissions associated with a product */
export type ProductResultNode = {
  __typename?: 'ProductResultNode';
  /**  # Summation of impacts occurring in supply chain (i.e. LCA results) */
  aggregatedImpactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  /**  # Impacts occurring in this node */
  impactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  productName?: Maybe<Scalars['String']>;
  /**  # A list of tags that can be used for grouping of the results. */
  tags?: Maybe<Array<Maybe<Tag>>>;
  /**  # Product nodes feeding into this node */
  upstreamProductNodes?: Maybe<Array<ProductResultNode>>;
};

/**   PRODUCTION SYSTEM INPUT */
export type ProductionSystem = {
  /**  # When the animals in the modelled production system will be processed too, the animal processing can be defined. */
  animalProcessing?: InputMaybe<AnimalProcessing>;
  /**  # A list of farms in the modelled production system. A farm is in a single location. At least one farm with one stage is needed. */
  growingFarms?: InputMaybe<Array<InputMaybe<GrowingFarm>>>;
  hatcheryFarms?: InputMaybe<Array<InputMaybe<HatcheryFarm>>>;
  /**  # The species of shrimp grown at the farm. */
  species?: InputMaybe<ShrimpSpecies>;
};

export type ProductionSystemInput = {
  /**  # A list of compound feeds that are used in the production system */
  compoundFeeds?: InputMaybe<Array<CompoundFeed>>;
  /**  # The foundational database that must be used for the impact calculations */
  databaseFoundation: DatabaseFoundation;
  /**  # Precalculated impacts to be used in LCA calculation */
  importedImpacts?: InputMaybe<Array<InputMaybe<ImportedImpact>>>;
  /**  # The shrimp production system */
  productionSystem: ProductionSystem;
  /**  # Split ecotoxicity in EF3.1. Default is True. */
  splitEcotoxicity?: InputMaybe<Scalars['Boolean']>;
};

export type ProductionSystemResult = {
  __typename?: 'ProductionSystemResult';
  /**  # Emissions occurring in this production system */
  emissionResults?: Maybe<Array<StageEmissionResult>>;
  /**  # A list of climate impact assessment results for all stages with an external output */
  impactResults?: Maybe<Array<ProductResultNode>>;
  /**  # None code terminating warnings */
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export type Query = {
  __typename?: 'Query';
  getResultByProductionSystemInput?: Maybe<ProductionSystemResult>;
  getValidationCodes?: Maybe<ValidationCodes>;
  /** @deprecated getValidationErrorCodes is deprecated. Use getValidationCodes instead. */
  getValidationErrorCodes?: Maybe<Array<Maybe<ValidationCode>>>;
};


export type QueryGetResultByProductionSystemInputArgs = {
  productionSystemInput: ProductionSystemInput;
};

export type Refrigerant = {
  /**  # The used amount of the refrigerant. In kilogram per year. */
  amount: Scalars['Float'];
  type: RefrigerantType;
};

export enum RefrigerantType {
  Ammonia = 'AMMONIA',
  CarbonDioxide = 'CARBON_DIOXIDE',
  Propane = 'PROPANE',
  RefrigerantHfc = 'REFRIGERANT_HFC'
}

export enum Salinity {
  /**  <18 ppm */
  BrackishOrFreshwater = 'BRACKISH_OR_FRESHWATER',
  /**  >18 PPM */
  Saline = 'SALINE'
}

export type SecondaryPackaging = {
  /**  # The amount of secondary packaging material. In kilogram. */
  amount: Scalars['Float'];
  type: SecondaryPackagingType;
};

export enum SecondaryPackagingType {
  CorrugatedBox = 'CORRUGATED_BOX'
}

export type ShrimpProductionChemical = {
  /**  # The amount used during the stage, in kilogram. */
  amount: Scalars['Float'];
  type: ShrimpProductionChemicalType;
};

export enum ShrimpProductionChemicalType {
  Chlorine = 'CHLORINE',
  CitricAcid = 'CITRIC_ACID',
  HydrogenPeroxide = 'HYDROGEN_PEROXIDE',
  Lime = 'LIME',
  PotassiumDiformate = 'POTASSIUM_DIFORMATE',
  PropionicAcid = 'PROPIONIC_ACID',
  SodiumMetasilicate = 'SODIUM_METASILICATE',
  SodiumPercarbonate = 'SODIUM_PERCARBONATE'
}

/**   SHRIMP MODELS */
export enum ShrimpSpecies {
  LitopenaeusVannamei = 'LITOPENAEUS_VANNAMEI',
  PenaeusMonodon = 'PENAEUS_MONODON'
}

export type SingleIngredientUse = {
  /**  # Weight percentage crude protein as a fraction of the feed weight. */
  crudeProteinContent: Scalars['Float'];
  /**  # ID of precalculated impacts to import. */
  importedImpactId?: InputMaybe<Scalars['ID']>;
  /**  # ID to find single ingredient in compound feed API. */
  ingredient: Ingredient;
  /**  # Total of the whole herd over the whole duration of the stage, in kilogram. */
  quantity: Scalars['Float'];
  /**  # A list of transports used to move the feed to the farm. */
  transport?: InputMaybe<Array<InputMaybe<Transport>>>;
};

export type StageEmissionResult = {
  __typename?: 'StageEmissionResult';
  emissions?: Maybe<Array<EmissionResult>>;
  stageId?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
};

export type Stock = {
  priceAtEnd?: InputMaybe<Scalars['Float']>;
  /**  # The price per weight of shrimp present at the start/end if the modelled period, in euro/kilogram. Default: weighted average of output prices. */
  priceAtStart?: InputMaybe<Scalars['Float']>;
  weightAtEnd?: InputMaybe<Scalars['Float']>;
  /**  # The total biomass of shrimp present at the start/end if the modelled period, in kilogram. Default: 0 */
  weightAtStart?: InputMaybe<Scalars['Float']>;
};

export type Tag = {
  __typename?: 'Tag';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/**   COMPOUND FEED MODELS */
export type TagInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TertiaryPackaging = {
  /**  # The amount of tertiary packaging material. In kilogram. */
  amount: Scalars['Float'];
  type: TertiaryPackagingType;
};

export enum TertiaryPackagingType {
  Pallet = 'PALLET',
  PlasticFilm = 'PLASTIC_FILM'
}

export type Transport = {
  /**  # In kilometer. */
  distance: Scalars['Float'];
  mode: TransportMode;
};

export enum TransportMode {
  InlandShip = 'INLAND_SHIP',
  SeaShip = 'SEA_SHIP',
  Train = 'TRAIN',
  Truck = 'TRUCK'
}

export type ValidationCode = {
  __typename?: 'ValidationCode';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type ValidationCodes = {
  __typename?: 'ValidationCodes';
  errorCodes?: Maybe<Array<Maybe<ValidationCode>>>;
  warningCodes?: Maybe<Array<Maybe<ValidationCode>>>;
};

export type Warning = {
  __typename?: 'Warning';
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/**   APS MODELS */
export type Water = {
  /**  # The amount water, in cubic meter. */
  amount: Scalars['Float'];
  type: WaterType;
};

export type WaterProperties = {
  /**   In cubic meter during the total of the stage. */
  amount: Scalars['Float'];
  /**  # in mg/L, default: */
  nitrogenConcentration?: InputMaybe<Scalars['Float']>;
};

export enum WaterType {
  /**  The water use, in cubic meter. */
  Water = 'WATER'
}
