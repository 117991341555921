import {
  BeefBaseline,
  BeefBreedingStageData,
  BeefGrowingStageData,
  BeefStage,
  BeefOutputBreeding,
  BeefOutputGrowing,
  BeefInputBreeding,
  BeefInputGrowing,
} from "../../../../sustell_15/models/Baseline/BeefBaseline";
import {
  createCorePayloadSustell,
  mapResourceUseSustell,
} from "./outCommonMapperSustellV2";
import {
  BeefBreedingStageDataDBModel,
  BeefStageDBModel,
  CommonStageInputModel,
  BeefGrowingStageDataDBModel,
  GrowingStageInputModel,
  BreedingOutputDBModel,
  GrowingOutputDBModel,
} from "../../../../sustell_15/models/TempBackendModels/TempBeefDB";
import {
  formatMassInput,
} from "./outMapperHelpers";
import {
  ProcessingStage,
  ProcessingStageData,
} from "../../../../sustell_15/models/Facility/Processing";
import { ProcessingStageDBModel } from "../../../../sustell_15/models/TempBackendModels/TempProcessingDB";
import { mapOutProcessingStage } from "./outProcessingMapperSustell";
import {
  StageDBModel,
  StageType,
} from "../../../../sustell_15/models/TempBackendModels/TempCattleDB";
import {
  mapFeedingProgramWithMothersMilk,
  mapCattleBeddingSystems,
  mapCattleEmissionsAndMitigations,
  mapCattleManureSystems,
  mapCattleStockChange,
  mapCattleTimeSpend,
  mapExternalSource,
  mapFeedingProgram,
  mapInternalSource,
  getStartDateFromStage,
} from "./Helpers/outCattleMapperHelpers";

function mapCalvesOutput(
  incomingData: BeefOutputBreeding
): BreedingOutputDBModel {
  if (!incomingData) return {} as BreedingOutputDBModel;

  return {
    animalsOutgoing: Number(incomingData.weanedCalvesToAnotherStage),
    averageWeight: formatMassInput(
      incomingData.averageWeightOfCalvesLeavingTheStage as number
    ),
    mortalities: Number(incomingData.numberOfMortalitiesCalves),
    soldExternally: Number(incomingData.weanedCalvesSold),
    weightAtMortality: formatMassInput(
      incomingData.averageWeightAtMortalityCalves as number
    ),
  };
}

function mapCowsOutput(
  incomingData: BeefOutputBreeding | BeefOutputGrowing
): BreedingOutputDBModel {
  return {
    animalsOutgoing: Number(incomingData.cowsToAnotherStage),
    averageWeight: formatMassInput(
      incomingData.averageWeightOfCowsLeavingTheStage
    ),
    mortalities: Number(incomingData.numberOfMortalitiesCows),
    soldExternally: Number(incomingData.cowsSold),
    weightAtMortality: formatMassInput(
      incomingData.averageWeightAtMortalityCows
    ),
  };
}

function mapGrowingStageCowsOutput(
  incomingData: BeefOutputGrowing
): GrowingOutputDBModel {
  return {
    ...mapCowsOutput(incomingData),
    averageAge: Number(incomingData.averageAge),
  };
}

function mapBreedingInput(
  incomingData: BeefInputBreeding
): CommonStageInputModel {
  const internalSources = mapInternalSource(incomingData?.internalSources);
  const externalSources = mapExternalSource(incomingData?.externalSources);

  return {
    animals: [...internalSources, ...externalSources],
    averageWeight: formatMassInput(incomingData?.averageWeightNewAnimals),
  };
}

function mapGrowingInput(
  incomingData: BeefInputGrowing
): GrowingStageInputModel {
  const internalSources = mapInternalSource(incomingData?.internalSources);
  const externalSources = mapExternalSource(incomingData?.externalSources);

  return {
    animals: [...internalSources, ...externalSources],
    averageWeight: formatMassInput(incomingData?.averageWeightNewAnimals),
    averageAge: Number(incomingData.averageAgeAtStart),
  };
}

function mapBreedingStageData(
  incomingData: BeefBreedingStageData
): BeefBreedingStageDataDBModel {
  const { housing } = incomingData;

  const mappedBeddingSystems = mapCattleBeddingSystems(housing?.beddingSystems);
  return {
    beddingCows: mappedBeddingSystems,
    beddingCalves: mappedBeddingSystems,
    emissionMitigationsCows: mapCattleEmissionsAndMitigations(
      incomingData.emissions?.cows
    ),
    emissionMitigationsCalves: mapCattleEmissionsAndMitigations(
      incomingData.emissions?.calves
    ),
    mmsCows: mapCattleManureSystems(incomingData.housing.cows?.manureSystems),
    mmsCalves: mapCattleManureSystems(
      incomingData.housing.calves?.manureSystems
    ),
    outputCalves: mapCalvesOutput(incomingData.output),
    outputCows: mapCowsOutput(incomingData.output),
    input: mapBreedingInput(incomingData.input),
    timeSpentCalves: mapCattleTimeSpend(incomingData.housing.calves),
    timeSpentCows: mapCattleTimeSpend(incomingData.housing.cows),
    stockChange: mapCattleStockChange(incomingData.input),
    feedingProgramCalves: mapFeedingProgramWithMothersMilk(incomingData.feed.calves),
    feedingProgramCows: mapFeedingProgram(incomingData.feed.cows),
    lengthStageCalves: Number(incomingData.input?.permanencePeriod) || 0,
    calvesBirthWeight: incomingData.input.averageWeightAtBirth
      ? formatMassInput(Number(incomingData.input.averageWeightAtBirth))
      : undefined,

    animalProductivity: incomingData.input.cattleProductivity,

    milkProduction: {
      fatContent: Number(incomingData.feed.calves.averageMilkFat),
    },
  };
}

function mapGrowingStageData(
  incomingData: BeefGrowingStageData
): BeefGrowingStageDataDBModel {
  return {
    feedingProgramCows: mapFeedingProgram(incomingData?.feed),
    beddingCows: mapCattleBeddingSystems(incomingData.housing?.beddingSystems),
    outputCows: mapGrowingStageCowsOutput(incomingData.output),
    input: mapGrowingInput(incomingData.input),
    timeSpentCows: mapCattleTimeSpend(incomingData.housing),
    animalProductivity: incomingData.input.cattleProductivity,
    emissionMitigationsCows: mapCattleEmissionsAndMitigations(
      incomingData.emissions
    ),
    mmsCows: mapCattleManureSystems(incomingData.housing.manureSystems),
    isStageRepeated: String(incomingData.input.isStageRepeated),
    numberOfRepetitions: Number(incomingData.input.numberOfRepetitions),
    growingPurpose: String(incomingData.input.growingPurpose) as
      | "REPLACEMENT"
      | "BEEF_PRODUCTION",
  };
}

function setAverageWeightOnProcessingStage(
  processingStage: ProcessingStage,
  averageWeight: number,
  quantity?: number
): ProcessingStage {
  const currentQuantity = processingStage.stageData.processingInput
    .quantity as number;
  return {
    ...processingStage,
    stageData: {
      ...processingStage.stageData,
      processingInput: {
        ...processingStage.stageData.processingInput,
        averageWeight,
        quantity: quantity || currentQuantity,
      },
    },
  };
}

function getProcessingStageWithAverageWeight(
  incomingData: BeefBaseline,
  processingStage: ProcessingStage
): ProcessingStage {
  const originStage = incomingData.stages.find(
    (stage) =>
      stage.id === processingStage.stageData?.processingInput?.originStageId
  );

  if (!originStage) return processingStage;

  if (originStage.type === StageType.Growing) {
    const originStageData = originStage.stageData as BeefGrowingStageData;
    const averageWeight =
      originStageData.output.averageWeightOfCowsLeavingTheStage;

    return setAverageWeightOnProcessingStage(processingStage, averageWeight);
  }

  if (originStage.type === StageType.Breeding) {
    const originStageData = originStage.stageData as BeefBreedingStageData;
    const isCowInput =
      processingStage.stageData.processingInput.animalType === "COW";
    const averageWeight = isCowInput
      ? originStageData.output.averageWeightOfCowsLeavingTheStage
      : originStageData.output.averageWeightOfCalvesLeavingTheStage;

    return setAverageWeightOnProcessingStage(
      processingStage,
      Number(averageWeight)
    );
  }

  if (originStage.type === StageType.Processing) {
    const originStageData = originStage.stageData as ProcessingStageData;
    const averageWeight = Number(
      originStageData?.processingOutput?.freshMeatAndEdibleOffal?.weight
    );
    const quantity = 1;
    return setAverageWeightOnProcessingStage(
      processingStage,
      averageWeight,
      quantity
    );
  }

  return processingStage;
}

export function getEndDateFromStage(
  incomingData: BeefGrowingStageData
) {
  return incomingData.input.endDate as string;
}

function mapStageData(
  incomingData: BeefStage | ProcessingStage,
  formData: BeefBaseline
): BeefStageDBModel | ProcessingStageDBModel {
  const { stageData } = incomingData;
  if (incomingData.type === StageType.Processing) {
    const processingStage = getProcessingStageWithAverageWeight(
      formData,
      incomingData as ProcessingStage
    );
    return mapOutProcessingStage(processingStage);
  }

  const commonStageData: Omit<StageDBModel, "stageData"> = {
    id: incomingData.id,
    name: incomingData.name,
  };

  if (incomingData.type === StageType.Breeding) {
    return {
      ...commonStageData,
      stageData: mapBreedingStageData(stageData as BeefBreedingStageData),
      type: StageType.Breeding,
      startDate: getStartDateFromStage(stageData as BeefBreedingStageData),
    };
  }

  return {
    ...commonStageData,
    stageData: mapGrowingStageData(stageData as BeefGrowingStageData),
    type: StageType.Growing,
    startDate: getStartDateFromStage(stageData as BeefGrowingStageData),
    endDate: getEndDateFromStage(stageData as BeefGrowingStageData),
  };
}

const mapBeefProductionDataOutSustell = (
  formData: BeefBaseline,
  isDraft: boolean
) => {
  try {
    let mappedData = createCorePayloadSustell(formData, isDraft);
    // TODO - Map resource is not typed, so it's not possible to fix this issue yet.
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    mappedData = mapResourceUseSustell(formData, mappedData);

    if (formData?.stages && !Array.isArray(formData.stages)) return mappedData;

    return formData.stages.reduce(
      (payload, stage) => {
        if (!stage?.stageData) return mappedData;

        const mappedStage = mapStageData(stage, formData);

        return {
          ...payload,
          stages: [
            ...payload.stages,
            {
              ...mappedStage,
              stageData: JSON.stringify(mappedStage.stageData),
            },
          ],
        };
      },
      { ...mappedData }
    );
  } catch (err) {
    console.error("Error on mapBeefProductionDataOutSustell: ", err);
  }

  return {};
};

export default mapBeefProductionDataOutSustell;
