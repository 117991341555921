import { cloneDeep } from "lodash";
import { useState, useEffect, useRef, FC, ReactNode, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Box, Tab, Tabs, Tooltip } from "@material-ui/core";
import { DsmButton, DsmIcon } from "@dsm-dcs/design-system-react";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import {
  beefTabsStyles,
  processAndStageStylesV2,
} from "../../../../../../_metronic/layout";
import { BaselineDialogProps, FormType } from "../../common";
import { DatabaseFoundation } from "../../../../../../graphql/generated/blonk/pigs";
import { CompoundFeedNameDatabase, Origin, SingleIngredient } from "../types";
import { Maybe, StageType } from "../../../../../../graphql/types";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { BaselineFeed } from "../../../../models/Baseline";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";

import {
  BeefBaseline,
  BeefBreedingFeed,
  BeefBreedingStageData,
  BeefGrowingStageData,
  BeefStage,
} from "../../../../models/Baseline/BeefBaseline";
import { defaultValues } from "../../baselineDefaultValues";
import { getInitialDatabaseValue } from "../../../../helpers/initialValues";
import BeefFeedComponent from "./BeefFeedComponent";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import BeefTabLabelComponent from "./BeefTabLabelComponent";
import ErrorMessageComponent from "../../../helpers/ErrorMessageComponent";
import { TargetAnimal } from "../../../../models/Baseline/CattleBaseline";

interface BeefFeedBreedingDialogProps extends BaselineDialogProps {
  compoundFeeds: Maybe<Array<CompoundFeedNameDatabase>>;
  singleIngredients: Maybe<Array<SingleIngredient>>;
  origins: Maybe<Array<Origin>>;
  formErrors?: Record<string, unknown>;
}

interface TabPanelProps {
  children: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`beef-breeding-feed-${index}`}
      aria-labelledby={`beef-breeding-feed-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const beefFeedStageDefaultValues = defaultValues.BEEF.stages[0].stageData.feed;

type FormError = {
  [TargetAnimal.CALVES]: boolean;
  [TargetAnimal.COWS]: boolean;
};

const BeefFeedBreedingDialog: FC<BeefFeedBreedingDialogProps> = ({
  formVisible,
  formType,
  itemIndex,
  handleCancel,
  handleSave = handleCancel,
  compoundFeeds,
  singleIngredients,
  origins,
  formErrors,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  const formContext = useFormContext();
  const [currentTab, setCurrentTab] = useState(0);
  const [stageFormError, setStageFormError] = useState<FormError>({
    cows: false,
    calves: false,
  });
  const currResetValue = useRef<{
    feed: BeefBreedingFeed;
    databaseFoundation: DatabaseFoundation;
  }>();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.feed`;
  const classes: CSSClassesList = processAndStageStylesV2();

  const handleResetClick = useCallback(() => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as BeefBaseline;
      const breedingStageData = resetObject.stages[itemIndex]
        ?.stageData as BeefBreedingStageData;
      if (breedingStageData?.feed) {
        breedingStageData.feed = {
          ...currResetValue.current.feed,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel("reset");
  }, [formContext, handleCancel, itemIndex]);

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setCurrentTab(newValue);
  };

  const intl = useIntl();
  const classesTabs = beefTabsStyles();
  const baselineDatabaseFoundation =
    getInitialDatabaseValue<DatabaseFoundation>(
      formContext,
      fieldItemPrefix,
      userProfile
    );
  const initialDatabase = useRef<DatabaseFoundation>(
    getInitialDatabaseValue<DatabaseFoundation>(
      formContext,
      fieldItemPrefix,
      userProfile
    )
  );

  const setFormErrors = () => {
    const calvesErrors =
      formErrors &&
      (formErrors[TargetAnimal.CALVES] as Record<string, unknown>);
    const cowsErrors =
      formErrors && (formErrors[TargetAnimal.COWS] as Record<string, unknown>);

    if (!calvesErrors && !cowsErrors) return;

    const isCalvesTabError = Boolean(
      calvesErrors && Object.keys(calvesErrors).length
    );
    const isCowsTabError = Boolean(
      cowsErrors && Object.keys(cowsErrors).length
    );
    setStageFormError((prev) => ({
      ...prev,
      calves: isCalvesTabError,
      cows: isCowsTabError,
    }));
  };

  const isCalvesTabError = () => stageFormError.calves;

  const isCowsTabError = () => stageFormError.cows;

  const showFormError = () => stageFormError.calves || stageFormError.cows;

  const closeFormError = () =>
    setStageFormError((prev) => ({ ...prev, cows: false, calves: false }));

  const getErrorMessages = () => {
    const errorMessages: Array<string> = [];
    const cowTabErrorMessage = intl.formatMessage({
      id: `SUSTELL.STAGE.BEEF.STEPS.COWS.ERROR`,
    });
    const calvesTabErrorMessage = intl.formatMessage({
      id: `SUSTELL.STAGE.BEEF.STEPS.CALVES.ERROR`,
    });

    if (stageFormError.calves) errorMessages.push(calvesTabErrorMessage);

    if (stageFormError.cows) errorMessages.push(cowTabErrorMessage);

    return errorMessages;
  };

  const updateBreedingFeeds = (stage: BeefStage, index: number) => {
    const cowsFeeds = (stage.stageData as BeefBreedingStageData).feed.cows
      .compoundFeeds;
    const updatedCowsFeeds: BaselineFeed[] | undefined = cowsFeeds?.map(() => ({
      feedType: "",
      kgPerAnimal: 0,
    }));
    formContext.setValue(
      `stages.${index}.stageData.feed.cows.compoundFeeds`,
      updatedCowsFeeds
    );
    const calvesFeeds = (stage.stageData as BeefBreedingStageData).feed.calves
      .compoundFeeds;
    const updatedCalvesFeeds: BaselineFeed[] | undefined = calvesFeeds?.map(
      () => ({ feedType: "", kgPerAnimal: 0 })
    );
    formContext.setValue(
      `stages.${index}.stageData.feed.calves.compoundFeeds`,
      updatedCalvesFeeds
    );
  };

  const updateFeeds = (stage: BeefStage, index: number) => {
    const feeds = (stage.stageData as BeefGrowingStageData).feed.compoundFeeds;
    const updatedFeeds: BaselineFeed[] | undefined = feeds?.map(() => ({
      feedType: "",
      kgPerAnimal: 0,
    }));
    formContext.setValue(
      `stages.${index}.stageData.feed.compoundFeeds`,
      updatedFeeds
    );
  };

  const handleSaveClick = async () => {
    const currFormValues = formContext.getValues();
    formContext.reset(currFormValues, { errors: true });
    const stages = (currFormValues?.stages as Array<BeefStage>) || [];

    stages.forEach((stage: BeefStage, i: number) => {
      if (
        i !== itemIndex &&
        initialDatabase.current !== baselineDatabaseFoundation
      ) {
        if (stage.type === StageType.Breeding) updateBreedingFeeds(stage, i);
        else updateFeeds(stage, i);
      }
    });
    if (handleSave) {
      await formContext.trigger(`stages.${itemIndex}.stageData.feed.cows`);
      await formContext.trigger(`stages.${itemIndex}.stageData.feed.calves`);
      setFormErrors();
      handleSave("confirm");
    }
  };

  useEffect(() => {
    if (formVisible) {
      const serializedData = formContext.getValues(
        `stages.${itemIndex}.stageData.feed`
      ) as BeefBreedingFeed;
      currResetValue.current = cloneDeep({
        feed: serializedData,
        databaseFoundation: baselineDatabaseFoundation,
      });
      setFormErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const resetToDefaultValues = () => {
    formContext.setValue(
      `${fieldItemPrefix}.${TargetAnimal.CALVES}.averageMilkFat`,
      beefFeedStageDefaultValues.calves.averageMilkFat
    );
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="shapes/cube-02"
      formTitle={intl.formatMessage({
        id: "SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE",
      })}
      variant="ultrawide"
      introText={intl.formatMessage({
        id: "SUSTELL.STAGE.BEEF.FEED.BREEDING.DESCRIPTION",
      })}
    >
      {showFormError() && (
        <div style={{ paddingBottom: "var(--dsm-spacing-px-5)" }}>
          <ErrorMessageComponent
            onClose={closeFormError}
            messages={getErrorMessages()}
          />
        </div>
      )}

      <Box className={classesTabs.tabsBox}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="beef-breeding-feed"
          className={classesTabs.tabs}
        >
          <Tab
            label={
              <BeefTabLabelComponent
                targetAnimal={TargetAnimal.COWS}
                isTabError={isCowsTabError()}
              />
            }
          />
          <Tab
            label={
              <BeefTabLabelComponent
                targetAnimal={TargetAnimal.CALVES}
                isTabError={isCalvesTabError()}
              />
            }
          />
        </Tabs>
      </Box>

      <TabPanel value={currentTab} index={0}>
        <BeefFeedComponent
          formType={formType}
          fieldItemPrefix={`${fieldItemPrefix}.${TargetAnimal.COWS}`}
          targetAnimal={TargetAnimal.COWS}
          stageIndex={itemIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          formVisible={formVisible}
          origins={origins}
        />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <BeefFeedComponent
          formType={formType}
          fieldItemPrefix={`${fieldItemPrefix}.${TargetAnimal.CALVES}`}
          targetAnimal={TargetAnimal.CALVES}
          stageIndex={itemIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          formVisible={formVisible}
          origins={origins}
        />
      </TabPanel>
      <div className={classes.resetButtonContainer}>
        <DsmButton
          widthFull
          variant="text"
          onClick={() => resetToDefaultValues()}
          disabled={formType === FormType.View}
          className="mt-3"
        >
          <DsmIcon slot="before" name="arrows/refresh-cw-01" />
          <u style={{ textDecoration: "none" }}>
            {intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.INPUT.RESET_DEFAULT_VALUES",
            })}
          </u>
        </DsmButton>
        <Tooltip
          title={intl.formatMessage({
            id: "SUSTELL.RESET_DEFAULT.TOOLTIP",
          })}
          placement="left"
          className="ml-3"
          style={{ transform: "translateY(85%)" }}
        >
          <DsmIcon
            name="general/help-circle"
            className={classes.inlineTooltipClass}
            title=""
          />
        </Tooltip>
      </div>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleSaveClick}
        saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
      />
    </DialogContainer>
  );
};

BeefFeedBreedingDialog.defaultProps = {
  formErrors: undefined,
};

export default BeefFeedBreedingDialog;