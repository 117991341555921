import { ProcessingStage, ProcessingStageData } from "../Facility/Processing";
import {
  Baseline,
  BaselinePropertyValue,
} from "./Baseline";
import {
  CattleEmission,
  CattleFeed,
  CattleHousing,
  CattleHousingAndManure,
  CattleManureManagementSystemType,
  ExternalSource,
  InternalSource,
} from "./CattleBaseline";

export interface Stage {
  id: string;
  farmId: string;
  name: string;
  type: string;
  productionSystem: string;
}

export const availableMmsTypeForMonthlyStoragePeriods = [
  CattleManureManagementSystemType.LiquidCover,
  CattleManureManagementSystemType.LiquidCrust,
  CattleManureManagementSystemType.LiquidNoCrust,
  CattleManureManagementSystemType.Pit,
];

export const availableMmsTypeForOverOrUnderStoragePeriod = [
  CattleManureManagementSystemType.DeepBeddingActiveMixing,
  CattleManureManagementSystemType.DeepBeddingNoMixing,
];

export interface BeefInput {
  startDate: Date | string;
  cattleProductivity: "HIGH" | "LOW";
  averageWeightNewAnimals: number;
  internalSources: InternalSource[];
  externalSources: ExternalSource[];
}

export interface BeefInputBreeding extends BeefInput {
  animalsPresentAtStart: number;
  animalsPresentAtEnd: number;
  averageWeightAtStart: number;
  averageWeightAtBirth?: number;
  // TODO - Attribute used for the feedIntake estimator (must check how to handle in and out mapper)
  permanencePeriod?: number;
}

export interface BeefInputGrowing extends BeefInput {
  endDate: BaselinePropertyValue;
  isStageRepeated: BaselinePropertyValue;
  numberOfRepetitions: BaselinePropertyValue;
  growingPurpose: BaselinePropertyValue;
  averageAgeAtStart: BaselinePropertyValue;
}

export interface BeefBreedingFeed {
  cows: CattleFeed;
  calves: CattleFeed;
}
export interface BeefOutput {
  cowsToAnotherStage: number;
  averageWeightOfCowsLeavingTheStage: number;
  cowsSold: number;
  pricePerCowSold?: number;
  numberOfMortalitiesCows: number;
  averageWeightAtMortalityCows: number;
}

export interface BeefOutputCalves {
  weanedCalvesToAnotherStage: number;
  averageWeightOfCalvesLeavingTheStage?: number;
  weanedCalvesSold: number;
  pricePerWeanedCalfSold?: number;
  numberOfMortalitiesCalves: number;
  averageWeightAtMortalityCalves?: number;
}

export type BeefOutputBreeding = BeefOutput & BeefOutputCalves;

export interface BeefOutputGrowing extends BeefOutput {
  averageAge: number;
}
export interface BeefBreedingStageData {
  input: BeefInputBreeding;
  feed: BeefBreedingFeed;
  housing: CattleHousing;
  output: BeefOutputBreeding;
  emissions?: {
    cows?: CattleEmission;
    calves?: CattleEmission;
  };
}

export interface BeefGrowingStageData {
  feed: CattleFeed;
  input: BeefInputGrowing;
  housing: CattleHousingAndManure;
  output: BeefOutputGrowing;
  emissions?: CattleEmission;
}

export interface BeefStage extends Stage {
  stageData: BeefGrowingStageData | BeefBreedingStageData | ProcessingStageData;
}

export interface BeefBaseline extends Baseline {
  stages: Array<BeefStage | ProcessingStage>;
}
